import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Mdas } from "./components/mdas";
import styles from "./style.module.css";
import EtransacLogo from "../assets/Home/etransacLogo.png";
import RegularModal from "../components/Modal/regular";
import constants from "../constants";
import { formatAmount } from "../utils";
import backArrow from "../assets/arrowLeftWhite.svg";
import Alert from "../components/Alert";

axios.defaults.testURL = constants.BASE_URL;

const Etransacpayment = () => {
	const history = useHistory();
	const { state: locationState, search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);

	const [amount, setAmount] = useState(query.get("amount") || "");
	const [name, setName] = useState(query.get("name") || "");
	const [ESBN, setESBN] = useState(query.get("esbn") || "");
	const [billNumber, setBillNumber] = useState(
		locationState?.billNumber || query.get("billNumber") || ""
	);
	const [fetchingBill, setFetchingBill] = useState(false);
	const [billInfo, setBillInfo] = useState(null);
	const [address, setAddress] = useState(query.get("address") || "");
	const [revenue, setRevenue] = useState(query.get("revcode") || "");
	const [revenues, setRevenues] = useState([]);
	const [mda, setMda] = useState(query.get("mdacode") || "");
	const [remark, setRemark] = useState("");
	const [paying, setPaying] = useState(false);
	const [consolidatedPaymentAlertOpen, setConsolidatedAlertOpen] =
		useState(false);
	const [payingWithBillNumber, setPayingWithBillNumber] = useState(
		query.get("method") === "2" ? false : true
	);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const publicKey = "1PUB1003LTbWjKNMe999P7dURzmNkFm5R3mssy";

	const startPayment = async () => {
		try {
			if (billInfo?.CentralisedBillDetails?.length > 1 && !amount) {
				billInfo.CentralisedBillDetails.map(async (bill) => {
					await handlePost(bill.Amount, bill.RevenueCode);
				});
			} else if (billInfo?.CentralisedBillDetails?.length > 1 && amount) {
				billInfo.CentralisedBillDetails.map(async (bill) => {
					let amt =
						(bill.Amount / billInfo.Total_Grand_Bill_Amount) *
						amount;

					await handlePost(amt, bill.RevenueCode);
				});
			} else await handlePost();
		} catch (error) {
			if (error.response) {
				setPaying(false);
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevenues(res);
	};

	useEffect(() => {
		RevenueHead();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mda]);

	useEffect(() => {
		const getBillAmount = async () => {
			try {
				setBillInfo(null);
				setFetchingBill(true);
				setRevenue("");
				setAmount("");
				let bill = (
					await axios.get(`/account/bill/${billNumber}`, {
						headers: { secureddata: "VGhpc2lzTm9ybGljcw==" }
					})
				).data.data;

				setBillInfo(bill);
				setName(bill?.Name_on_Bill);
				setESBN(bill?.ESBN_PID_on_Bill);
				setAddress(bill?.CentralisedBillDetails[0].Address);

				if (bill?.CentralisedBillDetails?.length === 1) {
					const mdaCode =
						bill?.CentralisedBillDetails[0].RevenueCode.split(
							"/"
						)[0];

					setMda(mdaCode);
					setRevenue(bill?.CentralisedBillDetails[0].RevenueCode);
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response.data?.msg
					});
				}
			} finally {
				setFetchingBill(false);
			}
		};
		if (billNumber?.length >= 17) getBillAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billNumber]);

	function returnCallBackURL(revCode) {
		// if this page was opened from any bill payment module outside of the homepage
		if (locationState?.billNumber) {
			if (
				billInfo?.CentralisedBillDetails?.length > 1 &&
				billInfo?.CentralisedBillDetails[
					billInfo?.CentralisedBillDetails?.length - 1
				].RevenueCode === revCode
			)
				return locationState?.goBackTo
					? `${window.location.origin}/${locationState?.goBackTo}`
					: window.location.href;
			else if (
				billInfo?.CentralisedBillDetails?.length > 1 &&
				billInfo?.CentralisedBillDetails[
					billInfo?.CentralisedBillDetails?.length - 1
				].RevenueCode !== revCode
			)
				return window.location.href;
			else
				return locationState?.goBackTo
					? `${window.location.origin}/${locationState?.goBackTo}`
					: window.location.href;
		}
		// if this page was opened from another site other than ours
		else if (query.get("redirectURL")) return query.get("redirectURL");
		// if this page was opened from homepage
		else {
			return window.location.href;
		}
	}

	const handlePost = async (amt, revCode) => {
		setPaying(true);
		const postData = {
			callbackUrl: returnCallBackURL(revCode),
			amount: amt
				? amt
				: amount * 100 || 100 * billInfo?.Total_Grand_Bill_Amount,
			currency: "NGN",
			customerFirstName: ESBN ? ESBN : name,
			customerPhoneNumber: billInfo?.phoneNo,
			email: billInfo?.Email || "eirssupport@norlics.com.ng",
			metadata: {
				BillNumber: billNumber || "",
				Amount: amount,
				CustomerId: ESBN,
				payerPhoneNumber: billInfo?.PhoneNo || "",
				CustReference: name,
				Address: address,
				AgencyCode: revenue.split("/")[0],
				RevenueCode: revenue,
				Bill_from_which_Module: billInfo?.Bill_from_which_Module || ""
			}
		};

		try {
			const response = await axios.post(
				"https://api.credocentral.com/transaction/initialize",
				postData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: publicKey
						// Add other headers as needed
					}
				}
			);

			const transactionId = response?.data?.data?.credoReference;
			const redirectURL = `https://pay.credocentral.com/${transactionId}`;

			window.location.href = redirectURL;

			// Handle response as needed
		} catch (error) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response.data?.error.amount ||
					error.response.data?.errors?.[0]?.error ||
					error.response.data?.errors?.details[0]?.message
			});
			// Handle error as neededcredoReference
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{consolidatedPaymentAlertOpen && (
				<RegularModal
					showing={consolidatedPaymentAlertOpen}
					toggle={setConsolidatedAlertOpen}
				>
					<p>
						You will go through the payment{" "}
						{billInfo?.CentralisedBillDetails?.length} times. Click
						okay to continue.{" "}
					</p>
					<button
						className="primary__btn"
						style={{
							display: "block",
							margin: "40px auto 0",
							padding: "0 25px"
						}}
						onClick={() => {
							setConsolidatedAlertOpen(false);
							startPayment();
						}}
					>
						Okay
					</button>
				</RegularModal>
			)}
			<main className={styles.main}>
				<div className={styles.Etransac__header}>
					<button
						className={styles.go__back}
						onClick={() => history.goBack()}
					>
						<img src={backArrow} alt="go back arrow" />
					</button>
					<img src={EtransacLogo} alt="etransac logo" />
				</div>
				<div className={styles.radio__btns}>
					<div>
						<input
							id="payWithBillNo"
							type="radio"
							value={true}
							checked={payingWithBillNumber}
							onChange={(e) => {
								setPayingWithBillNumber(true);
								setName("");
								setESBN("");
								setAmount("");
								setBillNumber("");
								setBillInfo(null);
								setAddress("");
								setRevenue("");
								setMda("");
								setRemark("");
							}}
							disabled={
								query.get("method") === "2" ||
								query.get("billNumber")
							}
						/>
						<label htmlFor="payWithBillNo">
							Pay With Bill Number
						</label>
					</div>
					<div>
						<input
							id="payWithoutBillNo"
							type="radio"
							value={false}
							checked={!payingWithBillNumber}
							onChange={(e) => {
								setPayingWithBillNumber(false);
								setName("");
								setESBN("");
								setAmount("");
								setBillNumber("");
								setBillInfo(null);
								setAddress("");
								setRevenue("");
								setMda("");
								setRemark("");
							}}
							disabled={
								query.get("method") === "2" ||
								query.get("billNumber")
							}
						/>
						<label htmlFor="payWithoutBillNo">
							Pay{" "}
							<b>
								<i>Without</i>
							</b>{" "}
							Bill Number
						</label>
					</div>
				</div>
				<div className={styles.wrapper}>
					{payingWithBillNumber && (
						<div className={styles.single__input}>
							<label
								className={styles.input__label}
								htmlFor="BillNumber"
							>
								Bill Number{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								onChange={(e) => setBillNumber(e.target.value)}
								id="BillNumber"
								placeholder="Enter bill number"
								required
								value={billNumber}
								disabled={query.get("billNumber")}
							/>
						</div>
					)}
					<div className={styles.payments__double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="name"
							>
								Name <span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								id="amount"
								placeholder="Enter Name"
								required
								disabled={
									payingWithBillNumber ||
									query.get("method") === "2"
								}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="ESBN"
							>
								ESBN/PID <span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="ESBN"
								placeholder="Enter your ESBN"
								required
								value={ESBN}
								onChange={(e) => setESBN(e.target.value)}
								disabled={
									payingWithBillNumber ||
									query.get("method") === "2"
								}
							/>
						</div>
					</div>
					<div className={styles.single__input}>
						<label
							className={styles.input__label}
							htmlFor="address"
						>
							Address <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="address"
							placeholder="Enter address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							disabled={
								payingWithBillNumber ||
								query.get("method") === "2"
							}
							required
						/>
					</div>
					{/* Revenue Head Bill Breakdown */}
					{fetchingBill && (
						<p style={{ textAlign: "center" }}>Loading...</p>
					)}
					{billInfo && (
						<table className={styles.table}>
							<thead>
								<tr>
									<th>S/N</th>
									<th>CATEGORY (REVENUE HEAD)</th>
									<th>Outstanding Bill (₦)</th>
									<th>Current Bill (₦)</th>
								</tr>
							</thead>
							<tbody>
								{billInfo?.CentralisedBillDetails.map(
									(each, i) => (
										<tr key={each.MDA}>
											<td>{i + 1}.</td>
											<td>{each.BillDescription}</td>
											<td>{each.OutstandingAmount}</td>
											<td>
												₦{formatAmount(each.Amount)}
											</td>
										</tr>
									)
								)}
								<tr className={styles.no__border_bottom}>
									<td></td>
									<td></td>
									<td className={styles.discount}>
										DISCOUNT
									</td>
									<td className={styles.discount}>
										₦
										{formatAmount(
											billInfo?.Total_Discount_Bill_Amount
										)}
									</td>
								</tr>
								<tr className={styles.no__border_bottom}>
									<td></td>
									<td></td>
									<td className={styles.total}>TOTAL DUE</td>
									<td className={styles.total}>
										₦
										{formatAmount(
											billInfo?.Total_Grand_Bill_Amount
										)}
									</td>
								</tr>
							</tbody>
						</table>
					)}
					{(billInfo?.CentralisedBillDetails?.length === 1 ||
						!payingWithBillNumber) && (
						<div className={styles.payments__double__inputs}>
							<Mdas setValue={(val) => setMda(val)} value={mda} />
							<div>
								<label
									className={styles.input__label}
									htmlFor="mda"
								>
									Revenue{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="mda"
									className={styles.input__medium}
									name="mda"
									onChange={(e) => {
										setRevenue(e.target.value);
									}}
									value={revenue}
									disabled={
										(payingWithBillNumber &&
											(!billNumber ||
												!mda ||
												!billInfo)) ||
										(!payingWithBillNumber && !mda) ||
										query.get("method") === "2"
									}
									required
								>
									<option value="">
										{revenues
											? "--Select Revenue head--"
											: "Loading..."}
									</option>
									{revenues &&
										revenues.map((getRev) => (
											<option
												key={getRev.Code}
												value={getRev.Code}
											>
												{getRev.Description}
											</option>
										))}
								</select>
							</div>
						</div>
					)}
					{!payingWithBillNumber && (
						<div className={styles.single__input}>
							<label
								className={styles.input__label}
								htmlFor="remark"
							>
								Remark <span style={{ color: "red" }}>*</span>
							</label>
							<textarea
								id="remark"
								placeholder="Enter remark"
								value={remark}
								onChange={(e) => setRemark(e.target.value)}
								rows={3}
								disabled={payingWithBillNumber}
							/>
						</div>
					)}
					<div className={styles.single__input}>
						<label className={styles.input__label} htmlFor="amount">
							Amount to Pay (
							{billInfo?.CentralisedBillDetails?.length > 1 &&
								"Min. Amount: ₦ 2000."}{" "}
							Ignore and Click “Pay Now” to pay in full){" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="number"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
							id="amount"
							placeholder="Enter amount"
							required
						/>
						{billInfo?.CentralisedBillDetails?.length > 1 &&
							amount &&
							amount < 2000 && (
								<span style={{ color: "red" }}>
									Amount must be, at least, ₦ 2000
								</span>
							)}
					</div>
					<button
						onClick={() => {
							if (billInfo?.CentralisedBillDetails?.length > 1)
								setConsolidatedAlertOpen(true);
							else startPayment();
						}}
						className={["primary__btn", styles.btn].join(" ")}
						disabled={
							(payingWithBillNumber &&
								(!billNumber ||
									!billInfo ||
									(billInfo?.CentralisedBillDetails
										?.length === 1 &&
										(!revenue || !mda)))) ||
							(!payingWithBillNumber &&
								(!name ||
									!ESBN ||
									!address ||
									!mda ||
									!revenue ||
									!amount)) ||
							(amount &&
								billInfo?.CentralisedBillDetails?.length > 1 &&
								+amount < 50)
						}
					>
						{paying
							? `Paying ₦ 
						${formatAmount(amount || billInfo?.Total_Grand_Bill_Amount)}
						... `
							: `Pay ₦ 
						${formatAmount(amount || billInfo?.Total_Grand_Bill_Amount)}
						 now`}
					</button>
				</div>
			</main>
		</div>
	);
};

export default Etransacpayment;
