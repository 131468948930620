import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import styles from "../MDAs/style.module.css";
import anssidStyles from "./style.module.css";
import { exportExcel, remapObj } from "../../utils";

const coy_ANSSID = process.env.REACT_APP_ANSSID;

const TaxPayers = () => {
	const history = useHistory();
	const userInfo = JSON.parse(localStorage.getItem("user_info"));

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [ESBN, setESBN] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [corporateName, setCorporateName] = useState("");
	const [corporateEmail, setCorporateEmail] = useState("");
	const [corporatePhone, setCorporatePhone] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const filterRef = createRef();

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		[`${coy_ANSSID}`, "ESBN"],
		["Organization Name", "corporateName"],
		["Email Address", "email"],
		["Phone no", "phoneNumber"],
		["Taxpayer Type", "taxpayerType"],
		["Inputter", "Inputter"]
	];

	const getTaxPayers =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);
			if (!filterRef.current?.classList.contains(styles.no__display)) {
				filterRef.current?.classList.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			if (ESBN) {
				query += `esbn=${ESBN}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (corporateName) {
				query += `name=${corporateName}&`;
			}

			if (corporatePhone) {
				query += `phone=${corporatePhone}&`;
			}

			if (corporateEmail) {
				query += `email=${corporateEmail}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let { items, pagination } = await axios
					.get(
						`/admin/corporate-taxpayer/getallcoysinglepagetin?${query}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => (res.data || { data: {} }).data);

				items = items.map((d) => {
					return {
						...d,
						ESBN: d?.User?.Anssid,
						corporateName: d?.OrgName,
						email: d?.Email_Address,
						phoneNumber: d?.Phone_Number,
						taxpayerType: d?.User?.Account_Type
					};
				});

				if (!download) setData(items);
				setTotal(pagination.totalItems);

				return items;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	const clearFilters = () => {
		setESBN("");
		setStartDate("");
		setEndDate("");
		setCorporateName("");
		setCorporateEmail("");
		setCorporatePhone("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getTaxPayers()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getTaxPayers()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const exportAnalytics = async () => {
		const info = await getTaxPayers({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		if (!info) console.log("What da hell!");
		exportExcel(
			remapObj(info, headers),
			headers,
			`Corporate EBSN Report.xlsx`
		);
	};

	return (
		<div className={styles.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", anssidStyles.create__btn].join(
						" "
					)}
					onClick={() => {
						history.push("/corporate-identity-management");
					}}
				>
					Create {coy_ANSSID}
				</button>

				{userInfo?.email === "akunnaodo@gmail.com" && (
					<button
						className={[
							"secondary__btn",
							anssidStyles.create__btn
						].join(" ")}
						onClick={() => exportAnalytics()}
						// disabled={loading}
					>
						Download Report
					</button>
				)}
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!ESBN &&
								!startDate &&
								!endDate &&
								!corporateEmail &&
								!corporatePhone &&
								!corporateName
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="anssid">{coy_ANSSID}</label>
								<input
									name="anssid"
									id="anssid"
									type="text"
									value={ESBN}
									onChange={(e) => setESBN(e.target.value)}
									placeholder={coy_ANSSID}
								/>
							</div>
						</form>
						<p>By Organization</p>
						<form>
							<div>
								<label htmlFor="taxpayer_name">
									Organization's Name
								</label>
								<input
									name="taxpayer_name"
									id="taxpayer_name"
									type="text"
									value={corporateName}
									onChange={(e) =>
										setCorporateName(e.target.value)
									}
									placeholder="Eg Odogwu Okeke"
								/>
							</div>
							<div>
								<label htmlFor="corporate_email">
									Organization's Email
								</label>
								<input
									name="corporate_email"
									id="corporate_email"
									type="email"
									value={corporateEmail}
									onChange={(e) =>
										setCorporateEmail(e.target.value)
									}
									placeholder="Eg. chukwu@gmail.com"
								/>
							</div>
							<div>
								<label htmlFor="corporate_phone">
									Organization's Phone
								</label>
								<input
									name="corporate_phone"
									id="corporate_phone"
									type="number"
									value={corporatePhone}
									onChange={(e) =>
										setCorporatePhone(e.target.value)
									}
									placeholder="Eg. +2348012345678"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getTaxPayers()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getTaxPayers}
						onEdit={(data) => {
							history.push(`/corporate-identity-management`, {
								...data,
								userId: data.UserId,
								edit: true
							});
						}}
						onView={(data) => {
							history.push(`/corporate-identity-management`, {
								...data,
								userId: data.UserId,
								view: true
							});
						}}
						onProfile={(data) => {
							history.push(`/corporate-profiling`, {
								userId: data.UserId,
								profileId: data.ProfileId,
								ESBN: data.ESBN,
								orgName: data.corporateName,
								phoneNumber: data.phoneNumber
							});
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default TaxPayers;
