import { useState } from "react";
import { useHistory } from "react-router-dom";
import constants from "../../constants";
import closeModalIcon from "../../assets/Modal/closeModalIcon.svg";
import ESIRSLogo from "../../assets/logo.svg";
import styles from "./styles.module.css";

const PaymentModal = ({ showing, toggle = () => {}, billNumber, goBackTo }) => {
	const history = useHistory();

	const [paymentMethod, setPaymentMethod] = useState("");

	function handleChange(e) {
		let {
			target: { value, options }
		} = e;
		setPaymentMethod({
			value,
			paygateLink:
				options[options.selectedIndex].getAttribute("data-link")
		});
	}

	function handleSubmit(e) {
		e.preventDefault();

		history.push(`${paymentMethod?.paygateLink}`, {
			billNumber,
			goBackTo
		});
	}

	return (
		<>
			<div className={styles.payment__modal}>
				<div className={styles.payment__modal_header}>
					<img src={ESIRSLogo} alt="ESIRS logo" />
					Instant Tax Payment
					<img src={closeModalIcon} alt="close modal icon" />
				</div>
				<form
					className={styles.payment__options_form}
					onSubmit={handleSubmit}
				>
					<label htmlFor="paymentMethod">
						Select a payment method
					</label>
					<select
						id="paymentMethod"
						value={paymentMethod}
						onChange={handleChange}
					>
						<option value="">---Select payment method---</option>
						{constants.paymentGateways.map((gateway) => (
							<option key={gateway.name} data-link={gateway.link}>
								{gateway.name}
							</option>
						))}
					</select>
					<button
						className="primary__btn"
						type="submit"
						disabled={!paymentMethod}
					>
						Continue
					</button>
				</form>
			</div>
			<div
				className={styles.modal}
				onClick={() => toggle(!showing)}
			></div>
		</>
	);
};

export default PaymentModal;
