import styles from "./style.module.css";
import ErrorAlertIcon from "../../assets/alert.svg";

const Alert = ({ text, type, style={} }) => {
	return (
		<div
			className={
				type === "success"
					? [styles.alert, styles.success].join(" ")
					: type === "error"
					? [styles.alert, styles.error].join(" ")
					: styles.alert
			}
			style={style}
		>
			{type === "error" && (
				<img
					style={{ marginRight: "15px" }}
					src={ErrorAlertIcon}
					alt="error alert icon"
				/>
			)}
			{text}
		</div>
	);
};

export default Alert;
