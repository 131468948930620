import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { countries, countryKeys } from "../countries";
import Aside from "../components/Aside";
import Alert from "../components/Alert";
import arrowLeft from "../assets/arrowLeft.svg";
import styles from "../Identity-management-individual/style.module.css";

function titleCase(str) {
	return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
}

const CorporateInformation = () => {
	const history = useHistory();
	const { state: locationState } = useLocation();
	const userInfo = JSON.parse(localStorage.getItem("user_info"));

	//Corporate
	const [TIN, setTIN] = useState(
		locationState?.view || locationState?.edit
			? locationState?.tin || ""
			: ""
	);
	const [organisationName, setOrganisationName] = useState(
		locationState?.view || locationState?.edit
			? locationState?.OrgName
			: userInfo?.corpname
	);
	const [address, setAddress] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Office_Address
			: ""
	);
	const [LGAs, setLGAs] = useState([]);
	const [LGA, setLGA] = useState(
		locationState?.view || locationState?.edit ? locationState?.Lga : ""
	);
	const [states, setStates] = useState([]);
	const [state, setState] = useState(
		locationState?.view || locationState?.edit
			? locationState?.State
			: "Enugu"
	);
	const [selectedStateCode, setSelectedStateCode] = useState(null);
	const [zones, setZones] = useState([]);
	const [zone, setZone] = useState(
		locationState?.view || locationState?.edit ? locationState?.Zone : ""
	);
	const [cities, setCities] = useState([]);
	const [city, setCity] = useState(
		locationState?.view || locationState?.edit ? locationState?.City : ""
	);
	const [country, setCountry] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Country || "Nigeria"
			: "Nigeria"
	);
	const [CAC, setCAC] = useState(
		locationState?.view || locationState?.edit
			? locationState?.RC_Number
			: ""
	);
	const [businessTypes, setBusinessTypes] = useState([]);
	const [typeOfBusiness, setTypeOfBusiness] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Economic_Sector
			: ""
	);
	const [categoryOfBusiness, setCategoryOfBusiness] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Business_Category
			: ""
	);
	const [orgEmail, setOrgEmail] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Email_Address
			: userInfo?.account_type === "corporate"
				? userInfo?.email
				: ""
	);
	const [contactNumber, setContactNumber] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Phone_Number
			: userInfo?.account_type === "corporate"
				? userInfo?.phonenumber
				: ""
	);
	const [ownerESBN, setOwnerESBN] = useState(
		locationState?.view || locationState?.edit
			? locationState?.Business_Owner_Anssid
			: ""
	);
	const [tinError, setTinError] = useState("");
	const [submitting, setFetching] = useState(false);

	const [orgESBN, setOrgESBN] = useState(
		locationState?.view || locationState?.edit
			? locationState?.User?.Anssid
			: null
	);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getUserInfo = async () => {
		try {
			const info = await axios
				.get(`/auth/profile`)
				.then((res) => (res.data || {}).data);

			setOrgESBN(info.anssid);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const jtbChecks = async (e) => {
		const chk = e;

		if (chk !== "") {
			try {
				let urlPrefix = "";

				await axios
					.post(`${urlPrefix}/txn/jtb/verifyInd`, {
						tin: chk,
						userId: ""
					})
					.then((res) => {
						if (res?.data?.ResponseCode === "004") {
							setTinError(
								"TIN " + res?.data?.ResponseDescription
							);
							setAlert({
								...alert,
								showing: true,
								type: "error",
								message: res?.data?.ResponseDescription
							});
						}
					});
			} catch (err) {
				let message = "";
				if (err.response) {
					message =
						err.response.data.errors?.details[0].message ||
						err.response.data?.error ||
						err.response.data?.errors;
				} else {
					message = err.message;
				}
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						message ||
						"Something goes wrong. Unable to submit your request."
				});
			}
		}
	};

	const getLGAs = async () => {
		try {
			const info = await axios
				.get(`/settings/lga`)
				.then((res) => (res.data || {}).data);

			setLGAs(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const getStates = async () => {
		try {
			const info = await axios
				.get(`/settings/state`)
				.then((res) => (res.data || {}).data);

			setStates(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const getCities = async () => {
		try {
			const info = await axios
				.get(`/settings/locationsetup`)
				.then((res) => (res.data || {}).data);

			setCities(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const getZones = async (LGACode) => {
		try {
			const info = await axios
				.get(`/zone/${LGACode}`)
				.then((res) => (res.data || {}).data);

			setZones(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const getBusinessTypes = async () => {
		try {
			const info = await axios
				.get(`/settings/economicsector`)
				.then((res) => (res.data || {}).data);

			setBusinessTypes(info);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	useEffect(() => {
		getStates();
		getLGAs();
		getCities();
		getBusinessTypes();

		getUserInfo();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const stateCode = states.find(
			(each) => each.Description === state
		)?.Code;

		setSelectedStateCode(stateCode);
	}, [state, states]);

	useEffect(() => {
		const selectedLGACode = LGAs.find(
			(each) => each.Description === LGA
		)?.Code;

		getZones(selectedLGACode);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [LGA, LGAs]);

	// reset error state
	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const generateESBN = async () => {
		try {
			if (orgESBN) return orgESBN;

			let query =
				userInfo?.account_type === "corporate"
					? `userId=${userInfo.id}`
					: `userId=${locationState.UserId}`;

			const lgaId = LGAs.find((each) => each.Description === LGA).Code;

			const res = await axios
				.post(`/system/generateuseranssid?${query}`, {
					lgaId,
					lgaCode: lgaId,
					lgaSerial: lgaId
				})
				.then((res) => (res.data || {}).data);

			if (userInfo.account_type === "corporate") {
				if (res.anssid && !userInfo.anssid) {
					localStorage.setItem(
						"user_info",
						JSON.stringify({ ...userInfo, anssid: res.anssid })
					);
				}
			}

			return res.anssid;
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors ||
						error.response.data?.error
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const submit = async (e) => {
		e.preventDefault();
		setFetching(true);

		if (tinError !== "") {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: `TIN error: ${tinError}`
			});
		}

		try {
			const profileID = await axios
				.get("/getgeneralids/03")
				.then((res) => res.data.Id);

			const id = localStorage.getItem("id");

			const res = await axios
				.post(
					`/admin/individual-taxpayer/corporatesinglepagetin/${id}`,
					{
						tin: TIN,
						tinname: "",
						profile: profileID,
						org: organisationName,
						officeaddr: address,
						state,
						lga: LGA,
						city,
						zone,
						phone: contactNumber,
						email: orgEmail,
						rcnum: CAC,
						bizcategory: categoryOfBusiness,
						economicsector: typeOfBusiness,
						ownerESBN,
						inputter: orgEmail,
						authoriser: "",
						orgProfiles: []
					}
				)
				.then((res) => res.data);

			if (locationState?.edit) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: res.message
				});
			} else {
				generateESBN().then((ESBN) => {
					history.push("/CIM-success", ESBN);
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error?.message
				});
				console.error(error.message);
			}
		} finally {
			setFetching(false);
		}
	};

	const adminSubmit = async (e) => {
		e.preventDefault();
		setFetching(true);

		if (tinError !== "") {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: `TIN error: ${tinError}`
			});
		}

		try {
			const profileID = await axios
				.get("/getgeneralids/03")
				.then((res) => res.data.Id);

			const res = await axios
				.post(`/admin/corporate-taxpayer/admincorporatesinglepagetin`, {
					tin: TIN,
					tinname: "",
					profile: profileID,
					org: organisationName,
					officeaddr: address,
					state,
					lga: LGA,
					city,
					zone,
					phone: contactNumber,
					email: orgEmail,
					rcnum: CAC,
					bizcategory: categoryOfBusiness,
					economicsector: typeOfBusiness,
					ownerESBN,
					inputter: userInfo?.email,
					authoriser: "",
					orgProfiles: []
				})
				.then((res) => res.data);

			history.push("/CIM-success", res.anssid);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error?.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	const adminEdit = async (e) => {
		e.preventDefault();
		setFetching(true);

		if (tinError !== "") {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: `TIN error: ${tinError}`
			});
		}

		try {
			const res = await axios
				.put(
					`/admin/corporate-taxpayer/editcoysinglepagetin/${locationState?.UserId}`,
					{
						tin: TIN,
						tinname: "",
						profile: locationState?.ProfileId,
						org: organisationName,
						officeaddr: address,
						state,
						lga: LGA,
						city,
						zone,
						phone: contactNumber,
						email: orgEmail,
						rcnum: CAC,
						bizcategory: categoryOfBusiness,
						economicsector: typeOfBusiness,
						ownerESBN,
						inputter: locationState?.Inputter,
						authoriser: "",
						orgProfiles: [],
						modifier: userInfo?.email
					}
				)
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.message || res.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error?.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	return (
		<div className={styles.signup_page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<Aside />
			<main className={styles.auth__form__container}>
				{userInfo?.account_type !== "corporate" && (
					<button
						onClick={() => history.goBack()}
						className={styles.back__btn}
					>
						<img src={arrowLeft} alt="go back arrow icon" /> Back
					</button>
				)}
				<h1>Corporate Information</h1>
				<div className={styles.body}>
					<p>
						Please fill in your correct details in ALL the fields on
						this screen and Click Sign Up to get your ESBN.
					</p>
					<form
						className={styles.form}
						onSubmit={
							userInfo?.account_type === "corporate"
								? submit
								: locationState?.edit
									? adminEdit
									: adminSubmit
						}
					>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="tin">
									Tax Identification Number (TIN){" "}
								</label>
								<input
									id="tin"
									name="tin"
									type="text"
									placeholder="Input your TIN"
									onChange={(e) => {
										setTIN(e.target.value);
										jtbChecks(e.target.value);
									}}
									value={TIN}
									disabled={locationState?.view}
								/>
							</div>
							<div>
								<label htmlFor="organisationName">
									Organisation Name{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="organisationName"
									name="organisationName"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setOrganisationName(e.target.value);
									}}
									value={organisationName}
									required
									disabled={locationState?.view}
								/>
							</div>
						</div>
						<hr className={styles.hr} />
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="orgEmail">
									Email{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="orgEmail"
									name="orgEmail"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setOrgEmail(e.target.value);
									}}
									value={orgEmail}
									required
									disabled={
										locationState?.view ||
										locationState?.edit
									}
								/>
							</div>
							<div>
								<label htmlFor="contactNumber">
									Contact Number{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="contactNumber"
									name="contactNumber"
									type="tel"
									placeholder="enter..."
									onChange={(e) => {
										setContactNumber(e.target.value);
									}}
									value={contactNumber}
									requiredrequired
									disabled={
										locationState?.view ||
										locationState?.edit
									}
								/>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="ownerESBN">Owner's ESBN</label>
								<input
									id="ownerESBN"
									name="ownerESBN"
									type="number"
									placeholder="enter..."
									onChange={(e) => {
										setOwnerESBN(e.target.value);
									}}
									value={ownerESBN}
									disabled={locationState?.view}
								/>
							</div>
						</div>
						<hr className={styles.hr} />
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="categoryOfBusiness">
									Category of Business{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="categoryOfBusiness"
									name="categoryOfBusiness"
									onChange={(e) => {
										setCategoryOfBusiness(e.target.value);
									}}
									value={categoryOfBusiness}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Select Business Category---
									</option>
									<option>Sole Proprietor</option>
									<option>Partnership</option>
									<option>Limited Liability Company</option>
									<option>Public Liability Company</option>
									<option>Specialised Business</option>
								</select>
							</div>
							<div>
								<label htmlFor="typeOfBusiness">
									Type of Business{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="typeOfBusiness"
									name="typeOfBusiness"
									onChange={(e) => {
										setTypeOfBusiness(e.target.value);
									}}
									value={typeOfBusiness}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Select Type of Business---
									</option>
									{businessTypes.map((type) => (
										<option key={type.Description}>
											{type.Description}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="CAC">
									CAC{" "}
									{categoryOfBusiness ===
										"Limited Liability Company" && (
										<span style={{ color: "red" }}>*</span>
									)}
								</label>
								<input
									id="CAC"
									name="CAC"
									type="text"
									placeholder="enter..."
									onChange={(e) => {
										setCAC(e.target.value);
									}}
									value={CAC}
									required={
										categoryOfBusiness ===
										"Limited Liability Company"
									}
									disabled={locationState?.view}
								/>
							</div>
						</div>
						<hr className={styles.hr} />
						<label htmlFor="address">
							Address <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="address"
							name="address"
							type="text"
							placeholder="enter..."
							onChange={(e) => {
								setAddress(e.target.value);
							}}
							value={address}
							required
							disabled={locationState?.view}
						/>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="country">
									Country{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="country"
									name="country"
									onChange={(e) => {
										setCountry(e.target.value);
									}}
									value={country}
									required
									disabled={locationState?.view}
								>
									<option value="">
										---Select Country---
									</option>
									{countryKeys.map((key) => (
										<option
											value={countries[key].country}
											key={key}
										>
											{titleCase(
												countries[
													key
												].country.toLowerCase()
											)}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="state">
									State{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="state"
									name="state"
									onChange={(e) => {
										setState(e.target.value);
									}}
									value={state}
									required
									disabled={locationState?.view}
								>
									<option value="">---Select State---</option>
									{country === "Nigeria" ? (
										states?.map((state) => (
											<option key={state.Code}>
												{state.Description}
											</option>
										))
									) : (
										<option value="N/A">
											Not Applicable
										</option>
									)}
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="LGA">
									LGA <span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="LGA"
									name="LGA"
									onChange={(e) => {
										setLGA(e.target.value);
									}}
									value={LGA}
									required
									disabled={locationState?.view}
								>
									<option value="">---Select LGA---</option>
									{country === "Nigeria" ? (
										LGAs?.filter(
											(each) =>
												each.StateCode ===
												selectedStateCode
										).map((LGA) => (
											<option key={LGA.Code}>
												{LGA.Description}
											</option>
										))
									) : (
										<option value="N/A">
											Not Applicable
										</option>
									)}
								</select>
							</div>
							{LGA && (
								<div>
									<label htmlFor="zone">
										Zone{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="zone"
										name="zone"
										onChange={(e) => {
											setZone(e.target.value);
										}}
										value={zone}
										required
										disabled={locationState?.view}
									>
										<option value="">
											---Select Zone---
										</option>
										{country === "Nigeria" &&
										state === "Enugu" ? (
											zones?.map((zone) => (
												<option key={zone.Description}>
													{zone.Description}
												</option>
											))
										) : country === "Nigeria" &&
										  state !== "Enugu" ? (
											<option>Non-Indigene</option>
										) : (
											<option value="N/A">
												Not Applicable
											</option>
										)}
									</select>
								</div>
							)}
						</div>
						{LGA && (
							<div className={styles.double__inputs}>
								<div>
									<label htmlFor="city">
										City{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="city"
										name="city"
										onChange={(e) => {
											setCity(e.target.value);
										}}
										value={city}
										required
										disabled={locationState?.view}
									>
										<option value="">
											---Select City---
										</option>
										{country === "Nigeria" &&
										state === "Enugu" ? (
											cities?.map((city) => (
												<option key={city.Code}>
													{city.Description}
												</option>
											))
										) : country === "Nigeria" &&
										  state !== "Enugu" ? (
											<option>Non-Indigene</option>
										) : (
											<option value="N/A">
												Not Applicable
											</option>
										)}
									</select>
								</div>
							</div>
						)}
						<hr className={styles.hr} />
						{!locationState?.view && (
							<div className={styles.btn__div}>
								<button
									type="button"
									className="secondary__btn"
									onClick={
										locationState?.view ||
										locationState?.edit
											? () =>
													history.push(
														"/statetin/corporate"
													)
											: () => history.push("/home")
									}
								>
									Cancel
								</button>
								<button
									type="submit"
									className="primary__btn"
									disabled={
										!organisationName ||
										!address ||
										!zone ||
										!LGA ||
										!state ||
										!city ||
										!country ||
										!typeOfBusiness ||
										!categoryOfBusiness ||
										!orgEmail ||
										!contactNumber ||
										submitting
									}
								>
									{submitting ? "Submitting..." : "Submit"}
								</button>
							</div>
						)}
					</form>
				</div>
			</main>
		</div>
	);
};

export default CorporateInformation;
