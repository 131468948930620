/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../../components/Alert";
import styles1 from "./style.module.css";
import styles from "../../Receipt/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import Table from "../../../components/Table5";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import { formatAmount } from "../../../utils";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import backArrow from "../../../assets/arrowLeft.svg";
import PAYEEmodule from "../../../Dashboard/PAYE/Paye-Record/style.module.css";

import { isNull } from "url";

const GenerateConsolidated = () => {
	const [ownersesbn, setOwnersesbn] = useState("");
	const [ownername, setownersname] = useState("");
	const [residentialaddress, setResidentialaddress] = useState("");
	const [PhoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [subscription, setSubcription] = useState("monthly");
	const [billtype, setBillType] = useState("");
	const [mdaKode, setMdaKode] = useState("");
	const [mdaId, setMdaId] = useState("");
	const [revId, setRevId] = useState("");
	const [mda, setMDA] = useState("");
	const [billCategory, setBillCategory] = useState("");
	const [description, setDescription] = useState("");
	const [revenue, setRevenue] = useState("");
	const [inkomeGrp, setInkomeGrp] = useState("");
	const [discount, setDiscount] = useState(0);
	const [resDiscount, setResDiscount] = useState("");
	const [granddiscount, setGrandDiscount] = useState(0);
	const [taxoffice, setTaxOffice] = useState("");
	const [taxOfficeId, setTaxOfficeId] = useState("");
	const [finished, setFinished] = useState("0");
	let [tableId, setTableId] = useState("");
	let [editId, setEditId] = useState("");
	let [editFlg, setEditFlg] = useState(false);
	const [btnCalcClick, setBtnCalcClick] = useState(false);
	const [checkError, setCheckError] = useState(0);
	const [pid, setPID] = useState("");
	const [searchNumType, setSearchNumType] = useState("ESBN");
	const [selectYear, setSelectYear] = useState("");
	const [selectMonth, setSelectedMonth] = useState("");
	const [asssessduedate, setAssessDueDate] = useState("");
	const [assessyear, setAssessYear] = useState("");
	const [billCategories, setBillCategories] = useState([]);
	const [descriptions, setDescriptions] = useState([]);
	const [mdas, setMDAs] = useState([]);
	const [revenues, setRevenues] = useState([]);
	const [taxoffices, setTaxOffices] = useState([]);
	const [arrBtnClick, setArrBtnClick] = useState([]);

	// const [bills, setBills] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tmpData, setTmpData] = useState([]);

	const [amount, setAmount] = useState("");
	const [outstanding, setOutStanding] = useState("");
	let [fem, setFem] = useState([]);
	const [OutstandingDesciption, setOutStandingDescription] = useState("");
	let [total, setTotal] = useState(0);
	let [grandTotal, setGrandTotal] = useState(0);
	let [grandOutstanding, setGrandOutstanding] = useState(0);
	const [date, setDate] = useState(new Date());

	// const [loading, setLoading] = useState(false);
	const [isGenerate, setIsGenerate] = useState(false);
	let [getBillKnt, setGetBillKnt] = useState(0);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const history = useHistory();
	const historyState = history?.location?.state;
	const [invoiceData, setInvoiceData] = useState(null);
	const [gettingInvoice, setIsGettingInvoice] = useState(false);
	const [isSavingPage, setIsSavingPage] = useState(false);
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	let errFlg = 0;
	let kode;
	let inkome;
	let loadEditFlg = false;
	let tmpOutStand = 0;

	useEffect(() => {
		const getESBN = async () => {
			try {
				if (!ownersesbn || ownersesbn.length < 11) {
					throw new Error(
						"ownersesbn must be at least 11 characters"
					);
				}
				const res = await axios
					.get(`users/anssid?anssid=${ownersesbn}`)
					.then((res) => (res.data || {}).data);

				// setAccountType(res.accountType);
				if (res?.accounttype === "individual") {
					setownersname(`${res.firstname} ${res.surname}`);
					// setResidentialaddress(res?.residentialaddress);
					setPhoneNumber(res.phonenumber);
					// setSurname(res.surname);
				} else {
					setownersname(res?.name);
					// setResidentialaddress(res.stateOfficeAddress);
					setEmail(res.email);
					setPhoneNumber(res.phoneNumber);
				}
				setResidentialaddress(res.residentialaddress || "");
				setEmail(res.email);
				setAssessDueDate("2024-12-31");
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				}
			}
		};
		if (ownersesbn && ownersesbn?.length >= 10) {
			getESBN();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);
		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		handleSearchMDA();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//if editing...
	useEffect(() => {
		if (historyState.edit) {
			fetchDetails();
		}
		// eslint-disable-next-line
	}, [getBillKnt === "1"]);

	const handleSearchMDA = async () => {
		try {
			const response = await axios.get(`/settings/mdacompany`);
			const { data } = response.data;
			setMDAs(data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			}
		}
	};

	async function fetchDetails() {
		setIsGettingInvoice(false);
		try {
			let info;
			let infodat;
			const accessToken = localStorage.getItem("access_token");
			console.log("historystate -> ", historyState.billno);

			infodat = await axios
				.get(`/mdaconsoldn/${historyState.billno}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data || {});

			info = infodat.data;
			if (info) {
				const getbills = info.ConsolidatedBillMainDetails.map(
					(bill) => {
						let typeBill;
						if (bill.BillType === "3") {
							typeBill = "Registration";
						}
						if (bill.BillType === "2") {
							typeBill = "Renewal";
						}
						if (bill.BillType === "1") {
							typeBill = "Fixed";
						}
						if (bill.BillType === "4") {
							typeBill = "ManualEntry";
						}
						tmpOutStand += Number(bill.OutstandingAmount);

						return {
							mda: bill.MDAName,
							billtype: typeBill,
							billCategory: bill.ProfileCategory,
							description: bill.RevenueDescription,
							revenue: bill.RevenueDescription,
							amount: bill.Amount,
							outstanding: bill.OutstandingAmount || "",
							OutstandingDesciption: bill.OutStandingReason || "",
							mdaId: bill.MDA,
							revId: bill.RevenueCode,
							tableId: bill.id
						};
					}
				);
				setSearchNumType(info.ESBN_PID_Flg === "1" ? "ESBN" : "PID");
				setSubcription(
					info.BillFrequency === "1" ? "monthly" : "yearly"
				);
				setownersname(info.Name);
				setOwnersesbn(info.ESBN_PID);
				setSelectYear(info.FiscalYear);
				setSelectedMonth(info.FiscalMonth);
				setResidentialaddress(info.Address);
				info.BillFrequency === "1"
					? setDate(new Date(info.DueDate))
					: setAssessDueDate(new Date(info.DueDate));
				setPhoneNumber(info.PhoneNo);
				setTaxOffice(info.TaxOffice);
				setEmail(info.Email);
				setTableData(getbills);

				setTotal(info.TotalAmtBilled);
				setGrandOutstanding(tmpOutStand);
				setGrandTotal(info.GrandTotal); // TotalAmtBilled + tmpOutStand used coz of runtime calculation of grandtotal
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			}
		} finally {
			setGetBillKnt("1");
		}
	}

	const handleCat2Bill = async (cat) => {
		setDescriptions([]);
		try {
			setMdaKode(cat);
			const response = await axios
				.get(`/categorytarrifcommerces/${mdaId}/${cat}`)
				.then((res) => (res.data || {}).data);
			// console.log("handleCat2Bill => ", response);
			setDescriptions(response);
		} catch (err) {
			console.error(err);
		}
	};

	const handleMDAChange = async (kode, inc) => {
		setMdaKode(kode);
		const response = await axios
			.get(`/settings/profilecategorys/${kode}`)
			.then((res) => (res.data || {}).data);
		console.log("response => ", response);
		setBillCategories(response);

		const resp = await axios
			.get(`/settings/mdalinkedrevenue/${inc}`)
			.then((res) => (res.data || {}).data);
		console.log("resp => ", resp);
		setRevenues(resp);
	};

	//Revenue
	useEffect(() => {
		handleRevenue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const printInvoice = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(invoiceData);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	const handleRevenue = async () => {
		try {
			const response = await axios
				.get(`/settings/mdarevenue`)
				.then((res) => (res.data || {}).data);
			setRevenues(response);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			}
		}
	};

	// tax office
	useEffect(() => {
		handleTaxOffice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const handleTaxOffice = async () => {
		try {
			const response = await axios
				.get(`/settings/assesstaxoffice`)
				.then((res) => (res.data || {}).data);
			console.log("TaxOffice -->", response);
			setTaxOffices(response);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			}
		}
	};

	// // OnPost
	const view = async () => {};

	const edit = async () => {
		setIsSavingPage(true);

		try {
			// const getbillNum = await axios.get(`gettmsids/01`);
			console.log("tableData [edit] -> ", tableData);
			let grandsum = 0;

			const getEdtBill = tableData.map((bill) => {
				let typeBill;
				if (bill.billtype === "Registration") {
					typeBill = "3";
				}
				if (bill.billtype === "Renewal") {
					typeBill = "2";
				}
				if (bill.billtype === "Fixed") {
					typeBill = "1";
				}
				if (bill.billtype === "ManualEntry") {
					typeBill = "4";
				}
				grandsum =
					Number(grandsum) +
					Number(bill.amount) +
					Number(bill.outstanding);
				return {
					mda: bill?.mdaId,
					mdaname: bill.mda,
					billtype: typeBill,
					billcategory: bill.billCategory,
					billdescription: bill.description,
					billamount: bill.amount,
					outstandingamt: bill.outstanding,
					outstandingreason: bill.OutstandingDesciption,
					revenuecode: bill?.revId
				};
			});

			const userInfo = JSON.parse(localStorage.getItem("user_info"));
			const accessToken = localStorage.getItem("access_token");
			const editResp = await axios
				.put(`mdaconsoldn`, {
					fromcoyid: userInfo.mdaModuleCoy,
					billno: historyState.billno,
					esbnpid: searchNumType === "ESBN" ? ownersesbn : pid,
					esbn_pid_flg: searchNumType === "ESBN" ? "1" : "2",
					name: ownername,
					address: residentialaddress,
					phoneno: PhoneNumber,
					email,
					duedate: asssessduedate,
					processstatus: "Pending",
					paymentstatus: "Pending",
					billfrequency: subscription === "monthly" ? "1" : "0",
					mth: selectMonth,
					year: selectYear,
					inputter: userInfo.email,
					taxoffice: taxoffice,
					totalamtbilled: total,
					discount,
					discountreason: resDiscount,
					grandtotal: grandTotal,
					grandoutstanding: grandOutstanding,
					ConsolBillDetails: getEdtBill
				})
				.then((res) => res.data || {});
			setIsGettingInvoice(false);
			setInvoiceData(editResp);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setIsSavingPage(false);
		}
	};

	const submitForm = async () => {
		setIsGettingInvoice(true);

		try {
			const getbillNum = await axios.get(`gettmsids/01`);
			const getbills = tableData.map((bill) => {
				let typeBill;
				if (bill.billtype === "Registration") {
					typeBill = "3";
				}
				if (bill.billtype === "Renewal") {
					typeBill = "2";
				}
				if (bill.billtype === "Fixed") {
					typeBill = "1";
				}
				if (bill.billtype === "ManualEntry") {
					typeBill = "4";
				}

				return {
					mda: bill?.mdaId,
					mdaname: bill.mda,
					billtype: typeBill,
					billcategory: bill.billCategory,
					billdescription: bill.description,
					billamount: bill.amount,
					outstandingamt: bill.outstanding,
					outstandingreason: bill.OutstandingDesciption,
					revenuecode: bill?.revId
				};
			});

			const userInfo = JSON.parse(localStorage.getItem("user_info"));
			const accessToken = localStorage.getItem("access_token");
			const res = await axios
				.post(
					`mdaconsoldn`,
					{
						fromcoyid: userInfo.mdaModuleCoy,
						billno: getbillNum.data.Id,
						esbnpid: searchNumType === "ESBN" ? ownersesbn : pid,
						esbn_pid_flg: searchNumType === "ESBN" ? "1" : "2",
						name: ownername,
						address: residentialaddress,
						phoneno: PhoneNumber,
						email,
						duedate: asssessduedate,
						processstatus: "Pending",
						paymentstatus: "Pending",
						billfrequency: subscription === "monthly" ? "1" : "0",
						mth: selectMonth,
						year: selectYear,
						inputter: userInfo.email,
						taxoffice: taxoffice,
						totalamtbilled: total,
						discount,
						discountreason: resDiscount,
						grandtotal: grandTotal,
						grandoutstanding: grandOutstanding,
						ConsolBillDetails: getbills
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res);

			setInvoiceData(res.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.message ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setIsGettingInvoice(false);
		}
	};

	useEffect(() => {
		setAssessDueDate(assessyear !== "" ? `${assessyear}-12-31` : "");
	}, [assessyear]);

	const formatDate = () => {
		const input = asssessduedate;
		const inputDate = new Date(input);

		const year = inputDate.getFullYear();
		const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
		const day = inputDate.getDate().toString().padStart(2, "0");

		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	const headers = [
		["MDA", "mda"],
		[`Type of Bill`, "billtype"],
		["Category of Bill", "billCategory"],
		["Description of Bill", "description"],
		["Revenue", "revenue"],
		["Amount", "amount"],
		["Outstanding", "outstanding"],
		["OutstandingDescription  ", "OutstandingDesciption"]
	];

	return (
		<main className={[styles2.wrapper, styles.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<button
				className={`${PAYEEmodule.review_header_btn} ${PAYEEmodule.customBackButton}`}
				onClick={() =>
					history.push("/demand-notice/consoldemandnotice")
				}
			>
				<img
					src={backArrow}
					alt="backArrow"
					className={PAYEEmodule.backArrow}
				/>
				<span className={PAYEEmodule.backText}>Back</span>
			</button>
			<form
				className={styles1.columned__form}
				style={{ marginTop: "20px" }}
			>
				<div className={styles.receipt__page}>
					{alert.showing && (
						<Alert text={alert.message} type={alert.type} />
					)}
					<form className={styles3.columned__form}>
						{/* //////first///// */}
						<div className={styles3.double__inputs}>
							<div className={styles2.radio__button_div}>
								<span className={styles1.input__label}>
									Generate by
								</span>
								<div>
									<div>
										<input
											id="generateByPID"
											type="radio"
											value="PID"
											checked={searchNumType === "PID"}
											onChange={(e) =>
												setSearchNumType(e.target.value)
											}
										/>
										<label
											className={styles3.input__label}
											htmlFor="generateByPID"
										>
											PID
										</label>
									</div>
									<div>
										<input
											id="generateByESBN"
											type="radio"
											value="ESBN"
											checked={searchNumType === "ESBN"}
											onChange={(e) =>
												setSearchNumType(e.target.value)
											}
										/>
										<label
											className={styles3.input__label}
											htmlFor="generateByESBN"
										>
											ESBN
										</label>
									</div>
								</div>
							</div>

							<div className={styles2.radio__button_div}>
								<span className={styles1.input__label}>
									Scheduled for
								</span>
								<div>
									<div>
										<input
											id="monthlySchedule"
											type="radio"
											value="monthly"
											checked={subscription === "monthly"}
											onChange={(e) =>
												setSubcription(e.target.value)
											}
										/>
										<label
											className={styles3.input__label}
											htmlFor="monthlySchedule"
										>
											Monthly
										</label>
									</div>
									<div>
										<input
											id="yearlySchedule"
											type="radio"
											value="yearly"
											checked={subscription === "yearly"}
											onChange={(e) =>
												setSubcription(e.target.value)
											}
										/>
										<label
											className={styles3.input__label}
											htmlFor="yearlySchedule"
										>
											Yearly
										</label>
									</div>
								</div>
							</div>
						</div>

						{/* //////first//////// */}
						<div className={styles3.double__inputs}>
							{searchNumType === "ESBN" ? (
								<div>
									<label
										className={styles3.input__label}
										htmlFor="esbn"
									>
										ESBN{" "}
										{ownersesbn.length < 11 && (
											<span style={{ color: "red" }}>
												(ESBN must be 11 digits)
											</span>
										)}
									</label>
									<input
										id="esbn"
										type="text"
										value={ownersesbn}
										onChange={(e) =>
											setOwnersesbn(e.target.value)
										}
										placeholder="Enter ESBN Number"
										required
									/>
								</div>
							) : (
								<div>
									<label
										className={styles3.input__label}
										htmlFor="PID"
									>
										PID
									</label>
									<input
										id="PID"
										type="text"
										value={pid}
										onChange={(e) => setPID(e.target.value)}
										placeholder="Enter PID Number"
										required
									/>
								</div>
							)}

							<div>
								<label
									className={styles1.input__label}
									htmlFor="selectYear"
								>
									Year Schedule
								</label>
								<select
									id="selectYear"
									value={selectYear}
									onChange={(e) =>
										setSelectYear(e.target.value)
									}
									// disabled={historyState?.view}
									required
								>
									<option value="">Select</option>
									<option>2013</option>
									{new Array(new Date().getFullYear() - 2013)
										.fill(0)
										.map((_, i) => (
											<option key={i}>
												{2013 + i + 1}
											</option>
										))}
								</select>
							</div>
						</div>
						{/* ////////second/////////// */}
						<div className={`${styles3.double__inputs}`}>
							<div>
								<label
									className={styles3.input__label}
									htmlFor="ownername"
								>
									NAME <span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="ownername"
									type="text"
									value={ownername}
									onChange={(e) =>
										setownersname(e.target.value)
									}
									placeholder="Enter NAME"
									required
								/>
							</div>
							<div>
								<label htmlFor="selectMonth">
									Monthly Schedule
								</label>
								<select
									id="selectMonth"
									value={selectMonth}
									onChange={(e) => {
										setSelectedMonth(e.target.value);
									}}
									disabled={subscription === "yearly"}
								>
									<option value="" disabled>
										Select
									</option>
									{[...Array(12)].map((_, index) => (
										<option
											key={index}
											value={new Intl.DateTimeFormat(
												"en",
												{ month: "long" }
											).format(new Date(2000, index, 1))}
										>
											{new Intl.DateTimeFormat("en", {
												month: "long"
											}).format(new Date(2000, index, 1))}
										</option>
									))}
								</select>
							</div>
						</div>
						{/* /////third/////// */}
						<div className={styles3.double__inputs}>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="residentialaddress"
								>
									Address
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="residentialaddress"
									name="residentialaddress"
									style={{ marginTop: "0" }}
									className={styles3.select__input}
									value={residentialaddress}
									onChange={(e) =>
										setResidentialaddress(e.target.value)
									}
									placeholder="Enter your  Address"
									required
								/>
							</div>
							{subscription === "monthly" ? (
								<div>
									<label
										className={styles1.input__label}
										htmlFor="montlyduedate"
									>
										Due Date
									</label>
									<div>
										<DatePicker
											// value="monthlyduedate"
											id="monthlyduedate"
											selected={date}
											onChange={(date) => setDate(date)}
										/>
									</div>
								</div>
							) : (
								<div>
									<label
										className={styles1.input__label}
										htmlFor="yearlyduedate"
									>
										Due Date
									</label>
									<input
										name="asssessduedate"
										type="date"
										id="yearlyduedate"
										value={
											asssessduedate
												? formatDate()
												: asssessduedate
										}
										onChange={(e) =>
											setAssessDueDate(e.target.value)
										}
										disabled={true}
										required
									/>
								</div>
							)}
						</div>
						{/* ////////////fouth//////////// */}
						<div className={styles3.double__inputs}>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="PhoneNumber"
								>
									Phone Number{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="PhoneNumber"
									type="text"
									name="PhoneNumber"
									style={{ marginTop: "0" }}
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
									value={PhoneNumber}
									placeholder="Enter your Phone Number"
									required
								/>
							</div>

							<div>
								<label
									className={styles.input__label}
									htmlFor="taxoffice"
								>
									Tax Office
								</label>
								<select
									id="taxoffice"
									name="taxoffice"
									onChange={(e) => {
										setTaxOfficeId(
											e.target.selectedOptions[0].getAttribute(
												"taxcode"
											)
										);
										setTaxOffice(e.target.value);
									}}
									value={taxoffice}
									required
								>
									<option value="">--Select Tax--</option>
									{taxoffices.map((tax, index) => (
										<option
											value={tax.Description}
											taxcode={tax.Code}
											index={index}
										>
											{tax.Description}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className={styles3.double__inputs}>
							{/* //////////fifth/////////////// */}
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="email"
								>
									Email{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="email"
									type="text"
									name="email"
									style={{ marginTop: "0" }}
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									placeholder="Enter your email address"
									required
								/>
							</div>
						</div>
						<hr className={styles3.divider} />
						{/* //////////////////////Dropdowns//////////////////////////// */}
						<div className={styles3.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="mda"
								>
									MDA
								</label>
								<select
									id="mda"
									name="mda"
									onChange={(e) => {
										setMDA(e.target.value);
										kode =
											e.target.selectedOptions[0].getAttribute(
												"mdacode"
											);
										inkome =
											e.target.selectedOptions[0].getAttribute(
												"incomegroup"
											);
										console.log("kode - mda -> ", kode);
										console.log("inkome - mda -> ", inkome);
										setMdaId(kode);
										setInkomeGrp(inkome);
										handleMDAChange(
											e.target.selectedOptions[0].getAttribute(
												"mdacode"
											),
											inkome
										);
									}}
									value={mda}
									required
								>
									<option value="">--Select MDA--</option>
									{mdas.map((mda, index) => (
										<option
											value={mda.Coyname}
											mdacode={mda.CoyId}
											incomegroup={mda.income_group}
											index={index}
										>
											{mda.Coyname}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="billtype">
									Type of Bill
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="billtype"
									name="billtype"
									value={billtype}
									onChange={(e) => {
										console.log("MDA Bill -> ", mdaKode);
										setBillType(e.target.value);
									}}
									required
								>
									<option>--Select BillType--</option>
									<option value={"Renewal"}>Renewal</option>
									<option value={"Registration"}>
										Registration
									</option>
									<option value={"Fixed"}>Fixed</option>
									<option value={"ManualEntry"}>
										Manual Entry{" "}
									</option>
								</select>
							</div>
						</div>
						{/* /////// second row//////// */}
						<div className={styles3.double__inputs}>
							<div>
								<label htmlFor="billCategory">
									Category of Bill
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="billCategory"
									className={styles3.input__long}
									name="billCategory"
									onChange={(e) => {
										setBillCategory(e.target.value);
										console.log(
											"category  -> ",
											billCategory
										);
										const catkode =
											e.target.selectedOptions[0].getAttribute(
												"catcode"
											);
										console.log(
											"catkode - category -> ",
											catkode
										);
										handleCat2Bill(
											e.target.selectedOptions[0].getAttribute(
												"catcode"
											)
										);
									}}
									value={billCategory}
									required
								>
									<option>--Select Category--</option>
									{billCategories.map((category, index) => (
										<option
											value={category.Description}
											catcode={category.Code}
											index={index}
										>
											{category.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="description">
									Description Bill
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="description"
									className={styles3.input__long}
									name="description"
									value={description}
									onChange={(e) => {
										setDescription(e.target.value);
										console.log(
											"billtype - renewal -> ",
											e.target.selectedOptions[0].getAttribute(
												"renewal"
											)
										);
										if (billtype === "Renewal") {
											console.log(
												"billtype - renewal -> ",
												e.target.selectedOptions[0].getAttribute(
													"renewal"
												)
											);
											setAmount(
												e.target.selectedOptions[0].getAttribute(
													"renewal"
												)
											);
										}

										if (billtype === "Fixed") {
											console.log(
												"billtype - fixed -> ",
												e.target.selectedOptions[0].getAttribute(
													"fixed"
												)
											);
											setAmount(
												e.target.selectedOptions[0].getAttribute(
													"fixed"
												)
											);
										}
										if (billtype === "Registration") {
											console.log(
												"billtype - reg -> ",
												e.target.selectedOptions[0].getAttribute(
													"reg"
												)
											);

											setAmount(
												e.target.selectedOptions[0].getAttribute(
													"reg"
												)
											);
										}
									}}
									required
								>
									<option>--Select Description --</option>
									{descriptions.map((descrip, index) => (
										<option
											value={descrip.Description}
											descode={descrip.Code}
											renewal={descrip.RenewalFee}
											reg={descrip.RegistrationFee}
											fixed={descrip.FixedFee}
											index={index}
										>
											{descrip.Description}
										</option>
									))}
								</select>
							</div>
						</div>
						{/* ////////////third row/////////// */}
						<div className={styles3.double__inputs}>
							<div>
								<label htmlFor="revenue">
									Revenue
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="revenue"
									// className={styles.input__medium}
									// className={styles3.input__long}
									name={revenue}
									onChange={(e) => {
										setRevId(
											e.target.selectedOptions[0].getAttribute(
												"revcode"
											)
										);
										setRevenue(e.target.value);
									}}
									value={revenue}
									required
								>
									<option>--Select Revenue--</option>
									{revenues.map((rev, index) => (
										<option
											value={rev.Description}
											revcode={rev.Code}
											index={index}
										>
											{rev.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="amount"
								>
									Amount
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									id="amount"
									name="amount"
									type="number"
									style={{ marginTop: "0" }}
									// className={styles3.select__input}
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									placeholder=""
									required
								/>
							</div>
						</div>
						<div className={styles3.double__inputs}>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="outstanding"
								>
									Outstanding
								</label>
								<input
									id="outstanding"
									type="text"
									name="outstanding"
									style={{ marginTop: "0" }}
									onChange={(e) => {
										setOutStanding(e.target.value);
									}}
									value={outstanding}
									placeholder="Enter Outstanding Bill "
								/>
							</div>
							<div>
								<label
									style={{ marginBottom: "15px" }}
									className={styles3.input__label}
									htmlFor="OutstandingDesciption"
								>
									Outstanding Desciption{" "}
								</label>
								<input
									id="OutstandingDesciption"
									type="text"
									name="OutstandingDesciption"
									style={{ marginTop: "0" }}
									onChange={(e) =>
										setOutStandingDescription(
											e.target.value
										)
									}
									value={OutstandingDesciption}
									placeholder="Enter Outstanding Description"
								/>
							</div>
						</div>
						<div className={styles1.add_btn}>
							<button
								className={[
									"primary__btn",
									styles1.add_btn
								].join(" ")}
								onClick={(e) => {
									e.preventDefault();
									console.log("clickme!!!");

									// error definitionSetCheckError
									if (amount === "" || Number(amount) === 0) {
										setCheckError(true);
										errFlg += 1;

										setAlert({
											...alert,
											showing: true,
											type: "error",
											message: "Amount can not be zero."
										});
										console.log(
											checkError,
											"-> checkerror[1]"
										);
										console.log("errflg[1]  -> ", errFlg);
									}
									if (revenue === "") {
										setCheckError(true);
										errFlg += 1;

										setAlert({
											...alert,
											showing: true,
											type: "error",
											message:
												"Revenue can not be blank!!!."
										});
										console.log(
											checkError,
											"-> checkerror[4]"
										);
										console.log("errflg[4]  -> ", errFlg);
									}
									if (
										Number(outstanding) > 0 &&
										OutstandingDesciption === ""
									) {
										setCheckError(true);
										errFlg += 1;
										setAlert({
											...alert,
											showing: true,
											type: "error",
											message:
												"Outstanding reason must be stated."
										});
										console.log(
											checkError,
											"-> checkerror[2]"
										);
										console.log("errflg [2] -> ", errFlg);
									}
									if (
										Number(outstanding) === 0 &&
										OutstandingDesciption !== ""
									) {
										setCheckError(true);
										errFlg += 1;
										setAlert({
											...alert,
											showing: true,
											type: "error",
											message:
												"Outstanding amount must be stated."
										});
										console.log(
											checkError,
											"-> checkerror[2]"
										);
										console.log("errflg [3] -> ", errFlg);
									}
									// error definition
									if (errFlg === 0) {
										fem =
											// ...fem,
											{
												mdaId,
												mda,
												billtype,
												billCategory,
												description,
												revenue,
												revId,
												amount,
												outstanding,
												OutstandingDesciption,
												tableId: uuidv4()
											};

										console.log("fem  --> ", fem);
										tmpData.push(fem);

										if (editFlg === true) {
											// Find the index of the element with the specified tableId
											const indexToEdit =
												tableData.findIndex(
													(item) =>
														item.tableId === editId
												);

											const newArray = [
												...tableData.slice(
													0,
													indexToEdit
												),
												{
													mdaId,
													mda,
													billtype,
													billCategory,
													description,
													revenue,
													revId,
													amount,
													outstanding,
													OutstandingDesciption,
													tableId: uuidv4()
												},
												...tableData.slice(
													indexToEdit + 1
												)
											];
											setTableData(newArray);

											setEditFlg(false);
											setEditId("");
										} else {
											setTableData([...tableData, fem]);
										}
										console.log(
											"tabledata [edit/add block] -> ",
											tableData
										);
										console.log(
											"tabkedata[arrbtnclick fro",
											tableData
										);
										setArrBtnClick(tableData);
									}
									errFlg = 0;
								}}
							>
								{editFlg === true ? "Edit Bill" : "Add Bill"}
							</button>
						</div>
						<hr className={styles3.divider} />
						<div
							className={styles1.adjust}
							style={{
								margin: "0px"
							}}
						>
							<div className={styles1.table__div}>
								<Table
									headers={headers}
									data={tableData}
									full
									onEdit={(data) => {
										console.log(
											"row data onEdit ->",
											data.tableId
										);

										setEditFlg(true);
										setEditId(data.tableId);

										setMDA(data.mda);
										setBillType(data.billtype);
										setBillCategory(data.billCategory);
										setDescription(data.description);
										setRevenue(data.revenue);
										setAmount(data.amount);
										setOutStanding(data.outstanding);
										setOutStandingDescription(
											data.OutstandingDesciption
										);

										setIsGettingInvoice(true);
										setInvoiceData(null);
									}}
									onDelete={(data) => {
										console.log(
											"row data onDelete ->",
											tableData.tableId
										);

										const { tableId } = data;

										// Find the index of the element with the specified tableId
										const indexToDelete =
											tableData.findIndex(
												(item) =>
													item.tableId === tableId
											);

										if (indexToDelete !== -1) {
											// Use splice to remove the element at the found index
											const updatedData = [...tableData];
											updatedData.splice(
												indexToDelete,
												1
											);
											setTableData(updatedData);
											console.log(
												"tabledata [delete block]-> ",
												tableData
											);

											setIsGettingInvoice(true);
										}
									}}
								/>
							</div>
						</div>
						<div className={styles1.undertable}>
							<div>
								<div>
									<label
										style={{ marginBottom: "15px" }}
										className={styles3.input__label}
										htmlFor="discount"
									>
										Discount
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										id="discount"
										type="text"
										name="discount"
										style={{ marginTop: "0" }}
										onChange={(e) => {
											setDiscount(e.target.value);
										}}
										value={discount}
										// placeholder="Enter Outstanding Description"
										// required
									/>
								</div>
								<div>
									<label
										style={{ marginBottom: "15px" }}
										className={styles3.input__label}
										htmlFor="resDiscount"
									>
										Reasons for Discount
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										id="resDiscount"
										type="text"
										name="resDiscount"
										style={{ marginTop: "0" }}
										onChange={(e) => {
											setResDiscount(e.target.value);
										}}
										value={resDiscount}
										// placeholder="Enter Outstanding Description"
										// required
									/>
								</div>
								<button
									id="btnCalc"
									className={[
										styles1.calc_btn,
										"primary__btn"
									].join(" ")}
									onClick={(e) => {
										let grdsum = 0;
										let grdout = 0;
										let grdTotal = 0;
										e.preventDefault();
										setBtnCalcClick(true);
										console.log(
											"arrBtnClick -> ",
											arrBtnClick
										);
										console.log("tabledata -> ", tableData);
										const gBill = tableData.map((bill) => {
											console.log(
												`startig=ng -> ${bill.amount}  |  amount -> ${bill.amount} `
											);
											grdTotal =
												Number(grdTotal) +
												Number(bill.amount);
											grdsum =
												Number(grdsum) +
												Number(bill.amount) +
												Number(bill.outstanding);
											grdout =
												Number(grdout) +
												Number(bill.outstanding || 0);
											return {
												grdsum,
												grdout,
												grdTotal
											};
										});
										setTotal(grdTotal);
										setGrandOutstanding(grdout);
										setGrandDiscount(discount);
										setGrandTotal(
											Number(grdTotal) +
												Number(grdout) -
												Number(discount)
										);
										console.log(
											`setTotal -> ${grdTotal}  |  setOutStand -> ${grandOutstanding} | setDiscount -> ${discount} | setGrandTotal -> ${grandTotal}`
										);
										console.log(
											`grdsum -> ${grdsum}  |  grdTotal -> ${grdTotal} | grdout -> ${grdout} | setGrandTotal -> ${grdTotal}`
										);
									}}
								>
									Calculate
								</button>
							</div>
							<div className={styles1.determinants}>
								<h3>
									Total Current Bill :{" "}
									<b>&#8358;{formatAmount(total)}</b>{" "}
								</h3>
								<h3>
									Total Outstanding Bill:
									<b>
										&#8358;
										{formatAmount(grandOutstanding)}
									</b>{" "}
								</h3>
								<h3>
									Grand Discount :
									<b>
										&#8358;
										{formatAmount(granddiscount)}
									</b>
								</h3>
								<h3>
									Total Due:{" "}
									<b>
										&#8358;
										{formatAmount(grandTotal)}
									</b>
								</h3>
							</div>
						</div>
						<button
							type="submit"
							className={[
								"primary__btn",
								styles.submit__btn
							].join(" ")}
							onClick={(e) => {
								e.preventDefault();
								if (historyState?.edit) edit();
								else submitForm();
							}}
							disabled={
								!ownername ||
								!amount ||
								!PhoneNumber ||
								!email ||
								!taxoffice ||
								!selectYear
							}
						>
							{isGenerate
								? "...Generating Consolidated Demand Notice"
								: "Generate Consolidated Demand Notice"}
						</button>
					</form>
				</div>
			</form>
			{invoiceData &&
				!gettingInvoice &&
				Boolean(
					userInfo?.roles?.[0]?.Claims.find(
						(claim) => claim.Route === "/mdaconsoldn"
					)
				) && (
					<>
						<button
							className={styles1.print__btn}
							onClick={(e) => printInvoice(e)}
						>
							Print
						</button>
						<div
							dangerouslySetInnerHTML={{ __html: invoiceData }}
							className={styles.doc__area}
						></div>
					</>
				)}
			{gettingInvoice && (
				<p style={{ textAlign: "center" }}>Loading Invoice...</p>
			)}
		</main>
	);
};

export default GenerateConsolidated;
